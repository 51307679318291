/* eslint no-console:0 */
import '../v3/css/main.scss'
import '../v3/js/main.js'
import { } from 'jquery-ujs'
import Cookies from 'js-cookie'

$(function(){
  $('.js-rating-like').on('click', function(){
    var me = $(this)
    var dataNode = me.closest('[data-rating]')
    var data = dataNode.data('rating')
    $.post(data.url, { score: 5 }, function(html){
      var div = $('<div>')
      div.append(html)
      dataNode.parent().prepend(div.find('.place-card__rating-wp').html())
      dataNode.remove()
    })
  })
})

$(function(){
  $('.js-toggle-format').on('click', function(){
    Cookies.remove('forceFormat')
    Cookies.set('forceFormat', 'v2', { expires: 730, path: '/' })
    location.reload()
  })
})
