// subscribe

class Subscribe {
  constructor() {
    this.elem = document.querySelector('.js-subscribe');

    if (!this.elem) return;

    this.content = this.elem.querySelector('.js-subscribe-content');
    this.success = this.elem.querySelector('.js-subscribe-success');
  }

  showSuccess() {
    if (!this.success || !this.content) return;

    this.content.classList.add('d-none');
    this.success.classList.remove('d-none', 'show');

    // плавно показать блок
    setTimeout(() => {
      this.success.classList.add('show');
    }, 0);
  }

  showContent() {
    if (!this.success || !this.content) return;

    this.success.classList.add('d-none');
    this.content.classList.remove('d-none', 'show');

    // плавно показать блок
    setTimeout(() => {
      this.content.classList.add('show');
    }, 0);
  }
}


export default Subscribe;
