// smooth-scrolling

const smoothScrolling = () => {
  const links = document.querySelectorAll('a[href^="#"]');

  if (!links.length) return;

  links.forEach((anchor) => {
    anchor.addEventListener('click', (e) => {
      if (e.target.classList.contains('prevent-default')) {
        e.preventDefault();
      }

      const target = `${e.target.getAttribute('href')}`;

      if (target === '#') return;

      const block = document.querySelector(target);

      if (!block) return;

      block.scrollIntoView({ behavior: 'smooth' });
    });
  });
};

export default smoothScrolling;
