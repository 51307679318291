
// tabs


class Tabs {
  constructor() {
    this.tabList = document.querySelectorAll('.js-tabs-control'); // список переключателей табов (ссылок)
    this.contentList = document.querySelectorAll('.js-tabs-content'); // список контента табов

    if (this.tabList.length && this.contentList.length) {
      this.settings();
    }
  }

  settings() {
    const jqContentList = $(this.contentList);
    const active = 'active';

    jqContentList.not('.active').hide(); // скрыть все другие блоки

    this.tabList.forEach((control, index) => {
      control.addEventListener('click', (e) => {
        if (control.classList.contains(active)) return;

        this.tabList.forEach(elem => elem.classList.remove(active));
        control.classList.add(active);

        jqContentList.hide(); // скрыть все другие блоки
        jqContentList.eq(index).show('normal', () => {
          window.msnries[index].layout();
        });
      });
    });
  }
}

export default Tabs;
