export default function FormValidate() {
  // Валидация формы
  function submitValidate() {
    const submit = $('.js-form button');
    const form = $('.js-form');

    form.on('submit', function (e) {
      e.preventDefault();

      const formValid = validate(this);
      if (formValid) {
        this.submit();
      }
    });

    function FormInput() {
      const inputs = document.querySelectorAll('.js-form .input__fluid[required]');
      const textarea = document.querySelectorAll('.js-form .textarea__fluid[required]');
      const select = document.querySelectorAll('.js-form .select__fluid[required]');
      const tel = document.querySelectorAll('.js-form .input__fluid[type="tel"]');
      const date = document.querySelectorAll('.date__fluid[name="date"][required]');

      if (inputs.length > 0) {
        for (let i = 0; i < inputs.length; i++) {
          if (inputs[i].getAttribute('type') != 'tel') {
            inputs[i].addEventListener('input', function () {
              if (this.value.length > 0) {
                if (this.parentElement.classList.contains('error')) {
                  this.parentElement.classList.remove('error');
                }
              }
            });
          }
        }
      }

      if (date.length > 0) {
        for (let i = 0; i < date.length; i++) {
          if (date[i].style.display != 'none') {
            date[i].addEventListener('change', function () {
              if (this.value.length > 0) {
                if (this.parentElement.classList.contains('error')) {
                  this.parentElement.classList.remove('error');
                }
              }
              this.setAttribute('value', this.value);
            });
          }
        }
      }

      if (tel.length > 0) {
        for (let i = 0; i < tel.length; i++) {
          tel[i].addEventListener('input', function () {
            if (this.value != '+7 (') {
              if (this.parentElement.classList.contains('error')) {
                this.parentElement.classList.remove('error');
              }
            }
          });
        }
      }

      if (textarea.length > 0) {
        for (let i = 0; i < textarea.length; i++) {
          textarea[i].addEventListener('input', function () {
            if (this.value.length > 0) {
              if (this.parentElement.classList.contains('error')) {
                this.parentElement.classList.remove('error');
              }
            }
          });
        }
      }
      if (select.length > 0) {
        for (let i = 0; i < select.length; i++) {

        }
      }
    }
    FormInput();
  }
  submitValidate();

  $('#inline_new_comment').append($("<input type='hidden' name='spamtrap' value='42'>"))

  $('.review__answer').on('click', function(e){
    $(this).closest('li').append($('#comments-form'))
    $('#inline_comment_parent_id').val($(this).data('parent-id'))
  })
}


export function validate(parent) {
  if (!parent) return true;

  const inputs = parent.querySelectorAll('.input__fluid[required]:not([disabled])');
  const textarea = parent.querySelectorAll('.textarea__fluid[required]:not([disabled])');
  const select = parent.querySelectorAll('.select__fluid[required]:not([disabled])');
  const tel = parent.querySelectorAll('.input__fluid[type="tel"]:not([disabled])');
  const date = parent.querySelectorAll('.date__fluid[name="date"]:not([disabled])');
  let formValid = true;
  if (inputs.length > 0) {
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].value.length == 0) {
        inputs[i].parentElement.classList.add('error');
        formValid = false;
      } else if (inputs[i].getAttribute('type') == 'email') {
        const myRe = new RegExp('^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$');
        const myEmail = myRe.exec(inputs[i].value);
        if (!myEmail) {
          inputs[i].parentElement.classList.add('error');
          formValid = false;
        }
      }
    }
  }
  if (tel.length > 0) {
    for (let i = 0; i < tel.length; i++) {
      if (tel[i].value != '+7 (' && tel[i].value.length > 4) {
        const myRe = new RegExp(/\+7\s?[\(]{0,1}9[0-9]{2}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}/);
        const myTel = myRe.exec(tel[i].value);
        if (!myTel) {
          tel[i].parentElement.classList.add('error');
          formValid = false;
        }
      }
    }
  }
  if (textarea.length > 0) {
    for (let i = 0; i < textarea.length; i++) {
      if (textarea[i].value.length == 0) {
        textarea[i].parentElement.classList.add('error');
        formValid = false;
      }
    }
  }
  if (date.length > 0) {
    for (let i = 0; i < date.length; i++) {
      if (date[i].value.length == 0 && date[i].style.display != 'none') {
        date[i].parentElement.classList.add('error');
        formValid = false;
      }
    }
  }
  if (select.length > 0) {
    for (let i = 0; i < select.length; i++) {
      if (select[i].value == 'Не выбрано' || select[i].value == '') {
        // $(select[i]).parent('.select-container').addClass('error');
        const parent = select[i].parentElement;
        const grandparent = parent.parentElement;
        if (parent.classList.contains('select-container')) {
          parent.classList.add('error');
          formValid = false;
        } else if (grandparent.classList.contains('select-container')) {
          grandparent.classList.add('error');
          formValid = false;
        }
      }
    }
  }
  return formValid;
}
