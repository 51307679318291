/* eslint-disable func-names */
// afisha-datepicker
// import '../datePicker/DatePickerX';


const afishaDatepicker = () => {
  const input = document.querySelector('.js-afisha-datepicker');

  if (!input) return;

  input.DatePickerX.init({
    mondayFirst: true,
    format: 'dd.mm.yyyy',
    minDate: new Date(),
    maxDate: new Date(9999, 11, 31),
    weekDayLabels: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
    shortMonthLabels: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июнь', 'Авг', 'Сен', 'Окт', 'Нояб', 'Дек'],
    singleMonthLabels: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    todayButton: false,
    todayButtonLabel: 'Сегодня',
    clearButton: false,
    clearButtonLabel: 'Сброс',
  });
  input.DatePickerX.setValue(input.getAttribute('value'), true);
  const inputDate = $(input);
  inputDate.on('change', function () {
    this.setAttribute('value', this.value);

    const date = this.value
      .split('.')
      .reverse()
      .join('/');


    let url = window.location.href.replace(window.location.hash, '');
    url = url.replace(/\/\d+\/\d+\/\d+$/, '');
    const newUrl = `${url}/${date}`;

    window.location = newUrl;
  });
};

export default afishaDatepicker;
