// js-place-info


import { lg } from './breakpoints';
import getScreenWidth from './getScreenWidth';


const placeInfo = () => {
  const el = document.querySelector('.js-place-info');

  const isLg = getScreenWidth() >= lg - 1;


  if (!el || !isLg) return;

  const elTop = el.getBoundingClientRect().top - document.body.getBoundingClientRect().top;

  window.addEventListener('scroll', () => {
    if (document.documentElement.scrollTop > elTop) {
      el.classList.add('fixed');
    } else {
      el.classList.remove('fixed');
    }
  });
};

export default placeInfo;
