import '../datePicker/DatePickerX';
import isMobile from '../../js/isMobile';

export default function FormDate() {
  if (document.querySelector('input[name="date"]')) {
    const dateMobile = $('.date-mobile');
    const dateDesc = $('.date-desc');
    if (isMobile()) {
      dateMobile.css('display', 'block');
      dateDesc.css('display', 'none');
      return;
    }
    dateMobile.css('display', 'none');
    dateDesc.css('display', 'block');
    const dateValue = dateDesc.attr('value');
    const FormatDate = NewFormatDate((new Date(dateValue)));
    dateDesc[0].setAttribute('value', FormatDate);

    const $myDatepicker = document.querySelector('input[name="date"][type="text"]');

    $myDatepicker.DatePickerX.init({
      mondayFirst: true,
      format: 'dd.mm.yyyy',
      minDate: new Date(),
      maxDate: new Date(9999, 11, 31),
      weekDayLabels: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
      shortMonthLabels: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июнь', 'Авг', 'Сен', 'Окт', 'Нояб', 'Дек'],
      singleMonthLabels: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      todayButton: false,
      todayButtonLabel: 'Сегодня',
      clearButton: false,
      clearButtonLabel: 'Сброс',
    });
    $myDatepicker.DatePickerX.setValue($myDatepicker.getAttribute('value'), true);
    const inputDate = $('input[name="date"]');
    inputDate.on('change', function () {
      this.setAttribute('value', this.value);
    });
  }
}
function NewFormatDate(date) {
  let newFormatDay = (date.getDate()).toString();
  let newFormatMonth = (date.getMonth() + 1).toString();
  if (newFormatDay.length == 1) {
    newFormatDay = `0${newFormatDay}`;
  }
  if (newFormatMonth.length == 1) {
    newFormatMonth = `0${newFormatMonth}`;
  }
  return `${newFormatMonth}.${newFormatDay}.${date.getFullYear()}`;
}
