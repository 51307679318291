// posting-info-table


import { md, lg } from '../../js/breakpoints';
import getScreenWidth from '../../js/getScreenWidth';
import grid from '../../js/grid';


const postingInfoTable = () => {
  const btn = document.querySelector('.js-posting-info-table-show');
  const tableCell = document.querySelectorAll('.js-posting-info-table tr');
  const jqTableCell = $(tableCell);

  if (!btn || !tableCell) return;

  btn.addEventListener('click', () => {
    jqTableCell.slideDown({
      start() {
        $(this).css({ display: 'flex' });
      }
    });
    btn.classList.add('d-none');
  });


  const width = getScreenWidth();
  const isGrid = width < lg && width >= md;

  if (isGrid) {
    const table = document.querySelector('.js-posting-info-table tbody');
    grid([table]);
  }
};

export default postingInfoTable;
