// afisha-nav


const afishaNav = () => {
  const block = document.querySelector('.js-scrolled-nav');

  if (!block) return;

  const top = block.offsetTop;

  window.addEventListener('scroll', () => {
    const scrolled = window.pageYOffset;

    // при скролле вниз
    if (scrolled > top) {
      block.classList.add('scrolled'); // opacity

      // при скролле верх
    } else {
      block.classList.remove('scrolled'); // показать
    }
  });
};

export default afishaNav;
