// form

// import FormPhone from '../form/form-phone';
// import FormSelect from '../form/form-select';
// import FormRadio from '../form/form-radio';
import FormDate from '../form/form-date';
import FormValidate from '../form/form-validate';

export default function form() {
  if (document.querySelector('.js-form')) {
    const inputs = document.querySelectorAll('.input__fluid');
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('input', function () {
        this.setAttribute('value', this.value);
      });
    }
    // FormPhone();
    // FormSelect();
    // FormRadio();
    // FormDate();

    // Ионки Крестик и ошибка
    const cross = document.querySelectorAll('.input__icon .icon-cross');
    for (let i = 0; i < cross.length; i++) {
      cross[i].addEventListener('click', function () {
        const icon = this.parentElement;
        const label = icon.parentElement;
        const thisInput = label.querySelector('.input__fluid');

        setTimeout(() => {
          if (thisInput.getAttribute('type') != 'tel') {
            thisInput.value = '';
            thisInput.setAttribute('value', '');
          } else {
            thisInput.value = '+7 (';
            thisInput.setAttribute('value', '+7 (');
          }
          if (label.classList.contains('error')) {
            label.classList.remove('error');
          }
        }, 30);
      });
    }

    $('.page').on('click', (e) => {
      closeOnOverlayClick(e);
    });
    $('.modal').on('click', (e) => {
      closeOnOverlayClick(e);
    });

    FormValidate();
  }
}


function closeOnOverlayClick(e) {
  let { target } = e;
  const content = document.querySelector('.select__gap.on');
  if (content) {
    const container = $(content).parent('.select').parent('.select-container');
    const arrow = container.children('.select-arrow');
    while (target && target !== content && target !== arrow[0]) {
      target = target.parentNode;
    }

    if (!target) {
      if (content.classList.contains('on')) {
        const list = $(content).parent('.select').children('.select__list');
        content.classList.remove('on');
        arrow.removeClass('arrow-up');
        list.slideUp(300);
      }
    }
  }
}
