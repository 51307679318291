// places-nav

import { lg } from '../../js/breakpoints';
import getScreenWidth from '../../js/getScreenWidth';


const placesNav = () => {
  const parents = document.querySelectorAll('.js-places-nav-parent');
  const btns = document.querySelectorAll('.js-places-nav-btn');
  const lists = document.querySelectorAll('.js-places-nav-list');
  const scrollingLlist = document.querySelector('.js-places-nav-scrolling-list');

  const mobileModal = document.querySelector('.js-modal-places-nav');


  if (!parents.length
    || !btns.length
    || !lists.length
    || !mobileModal
    || !scrollingLlist) return false;


  const classes = 'show-menu';


  window.addEventListener('click', (e) => {
    if (!e.target.classList.contains('js-places-nav-btn')) {
      parents.forEach(item => item.classList.remove(classes));
    }
  });
  window.addEventListener('scroll', () => {
    parents.forEach(item => item.classList.remove(classes));
  });
  window.addEventListener('resize', () => {
    parents.forEach(item => item.classList.remove(classes));
  });
  scrollingLlist.addEventListener('scroll', () => {
    parents.forEach(item => item.classList.remove(classes));
  });


  btns.forEach((btn, index) => {
    btn.addEventListener('click', (e) => {
      e.preventDefault();


      const parent = parents[index];

      if (parent.classList.contains(classes)) {
        parent.classList.remove(classes);
        return;
      }

      parents.forEach(item => item.classList.remove(classes));

      const isShowModal = getScreenWidth() <= lg;

      if (isShowModal) {
        const cln = lists[index].cloneNode(true);
        mobileModal.innerHTML = '';
        mobileModal.appendChild(cln);

        window.openPopup(e, 'modal-places-nav');

        return;
      }

      const linkWithPadding = parent.querySelector('a');
      const styles = window.getComputedStyle(linkWithPadding, null);

      const paddingLeft = +styles.getPropertyValue('padding-left').match(/\d+/)[0];
      const paddingRight = +styles.getPropertyValue('padding-right').match(/\d+/)[0];

      const width = `${parent.clientWidth - paddingLeft - paddingRight}`;

      const scrollLeft = +scrollingLlist.scrollLeft;
      const offsetLeft = +parent.offsetLeft;

      const left = `${(offsetLeft + paddingLeft) - scrollLeft}`;

      lists[index].setAttribute('style', `left: ${left}px; width: ${width}px;`);

      parent.classList.add(classes);
    });
  });
};

export default placesNav;
