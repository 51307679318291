export default function MenuBurger() {
  const burger = $('.header__burger');
  const menuBurger = $('.menu-mobile');
  const cross = $('.menu-cross .icon-cross');
  burger.on('click', () => {
    menuBurger.slideToggle(300);
  });
  cross.on('click', () => {
    menuBurger.slideToggle(300);
  });
}
