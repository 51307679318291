// main-slider

import Swiper from 'swiper';
import { md } from '../../js/breakpoints';

const mainSliser = () => {
  // configure Swiper to use modules

  const mainSlider = new Swiper('.js-main-slider', {
    spaceBetween: 10,
    slidesPerView: 'auto',

    pagination: {
      el: '.js-main-slider-pagination',
      type: "bullets",
      clickable: true,
      dynamicBullets: false,
    },

    breakpoints: {
      [md]: {
        slidesPerView: 1,
        spaceBetween: 0
      },
    }
  });
};

export default mainSliser;
