
export default function modal() {
  const modals = document.querySelectorAll('.modal');

  if (!modals.length) return;

  // после загрузки страницы добавить возможность показать модальные окна
  // без этого модалки отображатся сразу
  setTimeout(() => {
    modals.forEach((elem) => {
      elem.classList.add('loaded');
    });
  }, 0);
}
