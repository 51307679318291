import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default function Search() {
  initSearchPage();

  const searchWrap = document.querySelector('.js-search-content');
  const showSearch = document.querySelector('.js-search');
  const overlay = document.querySelector('.modal__overlay');

  if (!searchWrap) return;

  const results = searchWrap.querySelector('.js-search-results');
  const input = searchWrap.querySelector('input');
  const btnClear = searchWrap.querySelector('.search__clear');
  const btnSrartSearch = searchWrap.querySelector('.search__start');

  const jqBtnClear = $(btnClear);
  const jqBtnSrartSearch = $(btnSrartSearch);

  const jqResults = $(results);
  const jqSearchBlock = $(searchWrap);

  btnClear.addEventListener('click', (e) => {
    e.preventDefault();
    input.value = '';
    jqBtnClear.hide();
    jqBtnSrartSearch.show('fast');
    jqResults.slideUp(200);
    input.focus();
  });

  input.addEventListener('input', (e) => {
    const { value } = e.target;

    // if (value) {
    //   jqBtnSrartSearch.hide();
    //   jqBtnClear.show('fast');
    //   jqResults.slideDown(200);
    // } else {
      jqBtnClear.hide();
      jqBtnSrartSearch.show('fast');
      jqResults.slideUp(200);
    // }
  });


  showSearch.addEventListener('click', () => {
    jqSearchBlock.slideDown(200);
    overlay.classList.add('active');
    input.focus();

    disableBodyScroll(searchWrap);
  });

  overlay.addEventListener('click', () => {
    jqSearchBlock.slideUp(200);
    overlay.classList.remove('active');
    jqResults.slideUp(200);
    jqBtnClear.hide();
    jqBtnSrartSearch.show();
    input.value = '';
    enableBodyScroll(searchWrap);
  });
}

function initSearchPage(){
  const searchWrap = $('.js-search-page');
  const searchInput = searchWrap.find('input.search-page__input');
  const btnClear = searchWrap.find('.input__icon');

  btnClear.on('click', (e) => {
    e.preventDefault();
    searchInput.val('');
    searchInput.focus();
  });

  searchInput.on('input', (e) => {
    console.info(searchInput.val())
    console.info(searchInput)
    if (searchInput.val() == '') {
      btnClear.hide();
    } else {
      btnClear.show();
    }
  })
}
