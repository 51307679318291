import Masonry from 'masonry-layout';
import getScreenWidth from './getScreenWidth';
import { md } from './breakpoints';

// grid
export default (currentElements) => {
  const elements = currentElements || document.querySelectorAll('.js-grid');

  if (!elements.length) return;


  const defaultWidth = getScreenWidth() > md ? 349 : 335;
  const defaultGutter = getScreenWidth() > md ? 38 : 19;

  const msnries = [];

  elements.forEach((elem) => {
    const item = elem.querySelector('.js-grid-item');
    if (item) {
      const width = parseInt(getComputedStyle(item).getPropertyValue('--width')) || defaultWidth;
      const gutter = parseInt(getComputedStyle(item).getPropertyValue('--gutter')) || defaultGutter;

      const msnry = new Masonry(elem, {
        itemSelector: '.js-grid-item',
        columnWidth: width,
        gutter
      });

      msnries.push(msnry);

      // msnry.layout();
    }
  });

  return msnries;
};
