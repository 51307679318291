import 'babel-polyfill';
import './swiped-events';
import '../blocks/share/share';
import popup, { openPopup } from './popup';
import modal from '../blocks/modal/modal';
import grid from './grid';
import form from '../blocks/form/form';
import '../blocks/datePicker/DatePickerX';
import MenuBurger from '../blocks/menu-mobile/menu-mobile';
import mainSliser from '../blocks/main-slider/main-slider';
import Subscribe from '../blocks/subscribe/subscribe';
import afishaNav from '../blocks/afisha-nav/afisha-nav';
import placesNav from '../blocks/places-nav/places-nav';
import postingInfoTable from '../blocks/posting-info-table/posting-info-table';
import Search from '../blocks/search/search';
import afishaDatepicker from '../blocks/afisha-datepicker/afisha-datepicker';
import Tabs from './tabs';
import smoothScrolling from './smooth-scrolling';
import placeInfo from './place-info';

window.addEventListener('load', () => {
  placesNav();
  popup();
  modal();
  window.msnries = grid();
  form();
  MenuBurger();
  mainSliser();
  afishaNav();
  smoothScrolling();
  Search();
  postingInfoTable();
  const tabs = new Tabs();
  placeInfo();
  afishaDatepicker();


  window.openPopup = (a, b, c) => openPopup(a, b, c);
  window.Subscribe = new Subscribe();
  // window.Subscribe.showSuccess();
  // window.Subscribe.showContent();
});
