import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import isMobile from '../js/isMobile';
import { validate } from '../blocks/form/form-validate';


export default function popup() {
  const overlay = document.querySelector('.modal__overlay');
  window.modalIsOpen = false;

  const swipeModal = document.querySelector('.js-swipe-modal');

  if (swipeModal) {
    const btn = swipeModal.querySelector('.modal__close');

    swipeModal.addEventListener('swiped', (e) => {
      const { dir, yStart, yEnd } = e.detail;

      if (dir === 'down' && (yEnd - yStart > 50)) {
        closePopup(e, btn, overlay);
      }
    });
  }

  overlay.addEventListener('click', (e) => {
    if (window.modalIsOpen) {
      const modal = document.querySelector('.modal.active');
      if (!modal) return;
      const target = modal.querySelector('.modal__close');
      if (!target) return;
      closePopup(e, target, overlay);
    }
  });


  window.addEventListener('click', (e) => {
    const { target } = e;

    if (target.dataset.popupTarget) {
      openPopup(e, target, overlay);
      console.log('popup');
    }
    if (target.dataset.popupClose) {
      closePopup(e, target, overlay);
    }
    if (target.dataset.popupConfirm) {
      closeConfirm(e, target, overlay);
    }
    if (target.className === 'modal active') {
      closeOnOverlayClick(e, target);
    }
  });
}


function closePopup(e, btn, overlay) {
  e.preventDefault();
  console.log('closePopup');
  const elem = document.getElementById(btn.dataset.popupClose);

  if (!elem) return;
  window.modalIsOpen = false;
  toggleScrollLock(enableBodyScroll, elem);
  elem.classList.remove('active');
  isModal(elem) ? overlay.classList.remove('active') : false;
}


export function openPopup(e, btn, overlay = document.querySelector('.modal__overlay')) {
  e.preventDefault();
  console.log('openPopup');

  const elem = typeof btn === 'string'
    ? document.getElementById(btn)
    : document.getElementById(btn.dataset.popupTarget);

  if (elem === undefined || elem.className.includes('confirm')) return;


  window.modalIsOpen = true;

  elem.scrollTop = 0;
  // если попап открыт, то он закрывается при клике на кнопку, которая его открыла
  if (elem.className.indexOf('active') === -1) {
    toggleScrollLock(disableBodyScroll, elem);
    elem.classList.add('active');
    if (typeof btn !== 'string') btn.classList.add('active');
    isModal(elem) ? overlay.classList.add('active') : false;
  } else {
    toggleScrollLock(enableBodyScroll, elem);
    elem.classList.remove('active');
    if (typeof btn !== 'string') btn.classList.remove('active');
    isModal(elem) ? overlay.classList.remove('active') : false;
  }
}


function closeOnOverlayClick(e, btn) {
  let { target } = e;
  const elem = document.querySelector('.modal.active');
  const content = elem.querySelector('.modal.active .modal__content');
  console.log('closeOnOverlayClick');
  while (target && target !== content) {
    target = target.parentNode;
  }

  if (!target) {
    window.modalIsOpen = false;
    toggleScrollLock(enableBodyScroll, elem);
    elem.classList.remove('active');
    btn.classList.remove('active');
    document.querySelector('.modal__overlay').classList.remove('active');
  }
}

function closeConfirm(e, btn, overlay) {
  e.preventDefault();
  e.stopPropagation();
  // проверка валидации, если кнопка в форме
  if (!validate(e.target.closest('form'))) {
    return;
  }
  console.log('closeConfirm');

  const elem = document.getElementById(btn.dataset.popupConfirm);

  if (!elem) return;
  window.modalIsOpen = false;
  elem.classList.remove('active');
  btn.classList.remove('active');
  elem.classList.add('confirm');

  toggleScrollLock(enableBodyScroll, elem);

  setTimeout(() => {
    elem.classList.remove('confirm');
  }, 300);

  isModal(elem) ? overlay.classList.remove('active') : false;
}

function isModal(elem) {
  return elem.dataset.isModal;
}

function toggleScrollLock(callBack, elem) {
  const mobile = isMobile();
  if (mobile) {
    callBack(elem.querySelector('.modal__content'), {
      reserveScrollBarGap: true,
    });
  } else {
    callBack(elem, {
      reserveScrollBarGap: true,
    });
  }
}
